
import { Controller } from "stimulus"
//import $ from "jquery/dist/jquery.min"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    window.connectionsController = this
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    
    $(".mdc-top-app-bar .connections").replaceWith(data);

    fetch("/en/card_records/1?card_id=" + event.currentTarget.dataset.cardid)
    .then(data => {return data.text();})
    .then(html => {
      $.magnificPopup.open({
        items: {
          src: html, 
          type: 'inline'
        },
      });

    });
  }

  getConnectionsBy(card_id) {
    $.magnificPopup.open({
      items: {
        src: "/en/card_records?card_id=" + card_id,
        type: 'ajax'
      }
    });
  }

  getConnections(event) {
    let card_id = event.currentTarget.dataset.cardid;
    this.getConnectionsBy(card_id);
  }

}