import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []


  loaded() {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code') == true) {
      this.openCodePopup();
    }
  }

  openCodePopup(event) {
    let _this = this;

    if (event) {
      event.preventDefault();
    }

    $.magnificPopup.open({
      items: {
        src: $("#code_popup_html").html(), // can be a HTML string, jQuery object, or CSS selector
        type: 'inline'
      },
      alignTop: true,
      callbacks: {
        open: function() { setTimeout(function() {$("input#token").focus();}, 100) },
        close: function() { _this.hideCodeError(); }
      }
    });

    //TODO: Improve this code
    var elements = $("[data-mdc-auto-init='MDCTextField']");
    elements.each(function( index ) {
      $( this ).attr("data-mdc-auto-init-state", null);
    });
    var components = window.mdcAutoInit();
    //alert(components.length);
    components.forEach((c) => c.layout && c.layout());

  }

  onCodeSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.error !== undefined) {
      $("#code_popup input#token").addClass("error");
      $("#code_popup .error_message").show().addClass("animate fade_in");
    }
  }
  
  hideCodeError() {
    $("#code_popup input#token.error").removeClass("error");
    $("#code_popup .error_message.animate").hide().removeClass("animate fade_in");
  }

}