
import mdcAutoInit from '@material/auto-init';
window.mdcAutoInit = mdcAutoInit;

import {MDCRipple} from '@material/ripple';
mdcAutoInit.register('MDCRipple', MDCRipple);

import {MDCTextField} from '@material/textfield';
mdcAutoInit.register('MDCTextField', MDCTextField);

import {MDCTabBar} from '@material/tab-bar';
mdcAutoInit.register('MDCTabBar', MDCTabBar);

import {MDCList} from '@material/list';
mdcAutoInit.register('MDCList', MDCList);

import {MDCDrawer} from "@material/drawer";
mdcAutoInit.register('MDCDrawer', MDCDrawer);

import {MDCChipSet} from '@material/chips';
mdcAutoInit.register('MDCChipSet', MDCChipSet);

import {MDCSelect} from '@material/select';
mdcAutoInit.register('MDCSelect', MDCSelect);

import {MDCTopAppBar} from '@material/top-app-bar';
mdcAutoInit.register('MDCTopAppBar', MDCTopAppBar);

import {MDCSwitch} from '@material/switch';
mdcAutoInit.register('MDCSwitch', MDCSwitch);

import {MDCFormField} from '@material/form-field';
mdcAutoInit.register('MDCFormField', MDCFormField);

import {MDCCheckbox} from '@material/checkbox';
mdcAutoInit.register('MDCCheckbox', MDCCheckbox);

import {MDCNotchedOutline} from '@material/notched-outline';
mdcAutoInit.register('MDCNotchedOutline', MDCNotchedOutline);

import {MDCSnackbar} from '@material/snackbar';
mdcAutoInit.register('MDCSnackbar', MDCSnackbar);

var drawer = null;

function initDrawer() {

  if (document.querySelector('.mdc-top-app-bar .menu') != null) {
    document.querySelector('.mdc-top-app-bar .menu').addEventListener('click', (event) => {
      drawer.open = !drawer.open;

      if ($("#main").offset().left == 0) {
        $("body").removeClass("sidebar_open");
        $("body").addClass("sidebar_closed");
      } else {
        $("body").addClass("sidebar_open");
        $("body").removeClass("sidebar_closed");
      }
    });
  }
}

$(window).resize(function() {
  resizeDrawer();
})

function resizeDrawer() {
  if ($(document).width() < 1100) { 
    drawer.open = false;
    setTimeout(function() {
      if ($(document).width() < 1100) $(".mdc-drawer").css("width", "101%");
    }, 300);
  } else {
    drawer.open = true;
    $(".mdc-drawer").css("width", "280px");
  }

  if ($("#main").offset().left == 0) {
    $("body").removeClass("sidebar_open");
    $("body").addClass("sidebar_closed");
  } else {
    $("body").addClass("sidebar_open");
    $("body").removeClass("sidebar_closed");
  }
  
}

window.onload = (event) => {
  drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));

  window.mdcAutoInit();
  initDrawer();
  resizeDrawer();
};