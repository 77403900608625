
import { Controller } from "stimulus"
//import $ from "jquery/dist/jquery.min"

export default class extends Controller {
  static targets = []

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    
    if (typeof(data) === 'object')
      this.changeToEditForm(data.contact_id)
    else
      this.getControllerByIdentifier("contacts").getcontacts()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  changeToEditForm(contact_id) {
    fetch("/en/contacts/"+contact_id+"/edit")
    .then(data => {return data.text();})
    .then(html => {
      $.magnificPopup.open({
        items: {
          src: html, // can be a HTML string, jQuery object, or CSS selector
          type: 'inline'
        },
        alignTop: true
      });
    });
  }

}
