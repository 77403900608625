import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  
  showUserSettings(event) {
    let user_id =  event.target.dataset.uid;
    fetch("/en/profile/" + user_id + "/edit")
    .then(data => {return data.text();})
    .then(html => {
      $.magnificPopup.open({
        items: {
          src: html,
          type: 'inline'
        },
        alignTop: true
      });
    });
  }

  onEditSuccess(event) {
    let [data, status, xhr] = event.detail;
    ///alert(JSON.stringify(document.getElementsByClassName("user_" + event.target.dataset.uid)));
    let els = document.getElementsByClassName("user_" + event.target.dataset.uid);

    var parser = new DOMParser();
    for (let el of els) {
      el.outerHTML = data;
    }

    $.magnificPopup.proto.close();
  }

  changeAvatar(event){
    var file = event.target.files[0];
    var name = file.name;

    if (name.length > 10) name = ".." + name.substring(name.length-8, name.length);
    $(".edit_user .upload_info").html(name + " (" + this.toKb(file.size) + "Kb)");
  }

  toKb(num) {
    var result = num/1024;
    result = (Math.round(result * 100) / 100);
    return result;
  }

  getAutocompleteList(event) {
    if (event.currentTarget.value.length > 2) {
      fetch("/en/users-autocomplete?search="+ event.currentTarget.value)
      .then(data => {return data.text();})
      .then(html => {
        $(".owners #autocomplete").html(html);
      });
    } else {
      $(".owners #autocomplete").html("");
    }
  }

  selectItem(event) {
    if (event.currentTarget.parentElement.id == "autocomplete") {
      let user_id = event.currentTarget.dataset.uid;
      //alert(document.querySelectorAll(".card_item_owner").length);
      let index = document.querySelectorAll(".card_item_owner").length - 1;
      
      let item = event.currentTarget.outerHTML;

      item = item.replace( /uniq_item_index/g, index );


      if ($("#owners_list").find("[data-uid=" + user_id + "]").length == 0) {
        $("#owners_list").append(item);
      } else {
        $("#owners_list").find(".hidden[data-uid=" + user_id + "]").find(".delete").val("");
        $("#owners_list").find(".hidden[data-uid=" + user_id + "]").removeClass("hidden");
      }

      $("input#new_owner").val("");
      $(".owners #autocomplete").html("");
    }
  }

  deleteItem(event) {
    if ($("#owners_list li:not(.hidden)").length > 1) {
      
      if (event.currentTarget.parentElement.getElementsByClassName("delete")[0] == null) {
        event.currentTarget.parentElement.remove();
      } else {
        event.currentTarget.parentElement.getElementsByClassName("delete")[0].value = "true";
        event.currentTarget.parentElement.classList.add("hidden");
      }
    }
  }

}