import { Controller } from "stimulus"
//import $ from "jquery/dist/jquery.min"

export default class extends Controller {
  static targets = [ "url" ,"output", "object", "key" ]

  initSearch(event) {
    alert();
  }

  changeValue(event) {
    var innovation_regex = /(https:\/\/)+(hundred.org\/\S+\/innovations\/)+([^\?\#]+)/gm
    var slug = innovation_regex.exec(this.urlTarget.value)
    if (slug != null && slug[3] != null) {
      this.outputTarget.innerHTML = "<div class='innovation_box'><div class='loader'></div></div";
      fetch("/en/getinnovation?innovation_slug=" + slug[3])
      .then(data => {return data.json();})
      .then(json => {
        //alert(JSON.stringify(json));
        if (json.error !== undefined) {
          this.outputTarget.innerHTML = "";
          this.objectTarget.value = null;
        } else {
          this.outputTarget.innerHTML = json.html;
          this.objectTarget.value = JSON.stringify(json.object);
        }
      });
    } else {
      this.outputTarget.innerHTML = "";
      this.objectTarget.value = null;
    }
  }

  loadInnovationsPage(event) {
    if ($("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      
      let page = 1;
      page = event.currentTarget.dataset.page;

      fetch("/en/innovations/?partial=t&page=" + page)
      .then(data => {return data.text();})
      .then(html => {
        if (html.length > 2) {
          $(".cards.innovations").append(html);
          $("#pagination_button").attr("data-page", parseInt(page) + 1);
          $("#pagination_button").removeClass("loading");
        } else {
          $("#pagination_button").remove();
        }
      });
    }
  }

  loadFundersPage(event) {
    if ($("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      
      let page = 1;
      page = event.currentTarget.dataset.page;

      fetch("/en/funders/?partial=t&page=" + page)
      .then(data => {return data.text();})
      .then(html => {
        if (html.length > 2) {
          $(".cards.funders").append(html);
          $("#pagination_button").attr("data-page", parseInt(page) + 1);
          $("#pagination_button").removeClass("loading");
        } else {
          $("#pagination_button").hide();
        }
      });
    }
  }

}