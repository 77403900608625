require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("packs/magnific.js")
require("packs/main.js")
require("packs/material.js")

import "controllers"

//Pagination button infinit scroll
if ($("#pagination_button").length > 0) {

  var scroll = function () {
    if (($("#pagination_button") !== undefined) && ($("#pagination_button").hasClass("hidden") == false) && ($("#pagination_button").hasClass("loading") == false)) {
      var pos = $(window).scrollTop();  
      var pagination_pos = 0;
      var window_height = window.innerHeight;
      if (document.getElementById("pagination_button") != null) {
        pagination_pos = document.getElementById("pagination_button").offsetTop;
        if (pos > pagination_pos - window_height - 100) {
          $("#pagination_button").click();
        }   
      }
    }
  };

  var raf = window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      window.oRequestAnimationFrame;
  var $window = $(window);
  var lastScrollTop = $window.scrollTop();

  if (raf) {
    loop();
  }

  function loop() {
    var scrollTop = $window.scrollTop();
    if (lastScrollTop === scrollTop) {
      raf(loop);
      return;
    } else {
      lastScrollTop = scrollTop;

      scroll();
      raf(loop);
    }
  }

}

