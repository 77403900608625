import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hidden", "hint", "icon"]


  toggleHidden() {
    if (this.hiddenTarget.value == "true") {
      this.hintTarget.innerHTML = "everyone can see your message"
      this.hiddenTarget.value = false
      this.iconTarget.innerHTML = "lock_open"
    } else {
      this.hintTarget.textContent = "private: only card owners can see your message"
      this.hiddenTarget.value = true
      this.iconTarget.innerHTML = "lock"
    }
  }

}