import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "output", "title", "body", "edit"]

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
  
  getCards(event) {
    let filter = null;
    let page = 1;

    if (event) {
      filter = event.currentTarget.dataset.filter;
      if (event.currentTarget.dataset.page !== undefined) {
        page = event.currentTarget.dataset.page;
      }
      event.preventDefault();
      if (filter !== undefined) {
        $("#pagination_button").attr("data-filter", filter);
      } else {
        $("#pagination_button").attr("data-filter", "all");
      }
      history.pushState(null, null, event.currentTarget.dataset.url);
    }

    var cardAPI = "/en/cards";
    let _this = this;

    if (page < 2 || $("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( cardAPI, {
        filter: filter,
        page: page,
        partial: true,
      })
      .done(function( data ) {
        if (page > 1) {
          if (data.length > 1) {
            $(".cards").append(data);
            $("#pagination_button").attr("data-page", parseInt(page) + 1);
          } else {
            $("#pagination_button").addClass("hidden");
          }
          $("#pagination_button.loading").removeClass("loading");
          
        } else {
          if (data.length > 1) { 
            $("#pagination_button.hidden").removeClass("hidden");
            $("#pagination_button").attr("data-page", 2);
          }

          $("#pagination_button.loading").removeClass("loading");
          _this.outputTarget.innerHTML = data;
        }

      });
      
    }
    $.magnificPopup.close({});
  }

  getArchivedCards(event) {
    if (event) {
      event.preventDefault();
      event.currentTarget.remove();
    }

    var cardAPI = "/en/cards";
    let _this = this;

    $.get( cardAPI, {
      filter: "archived",
      partial: true,
    })
    .done(function( data ) {
      _this.outputTarget.innerHTML +=  data;
    });
  }

  callCardEditForm(event) {
    let card_id = null;
    let fromShow = null;

    if ( event.target.dataset === undefined) {
      card_id =  $("#card_show").attr("data-uid");
      fromShow =  $("#card_show").attr("data-fromshow");
    } else {
      card_id =  event.target.dataset.uid;
      fromShow = event.target.dataset.fromshow;
    }

    this.getCardEditForm(card_id, fromShow);
  }
  
  getCardEditForm(card_id, fromShow) {
    let _this = this;
    let showParam = "";

    if (fromShow !== undefined && fromShow.length > 0)
      showParam = "?edit_in_show=" + fromShow;

    fetch("/en/cards/" + card_id + "/edit" + showParam)
    .then(data => {return data.text();})
    .then(html => {
      $.magnificPopup.open({
        items: {
          src: html,
          type: 'inline'
        },
        alignTop: true,
        callbacks: {
          beforeClose: function() {
          },
          open: function() {
            $.magnificPopup.instance.close = function(data) {

              // Do whatever else you need to do here
              if (data != "no_warn") {
                var confirmed = confirm("Are you sure?");
                if(!confirmed) {
                  return;
                }
              }
              $.magnificPopup.instance.close = function() {$.magnificPopup.proto.close.call(this);};
              // Call the original close method to close the popup
              $.magnificPopup.proto.close.call(this);
            };
          }
        }
      });

      if ((typeof google === 'undefined') || (typeof google.maps === 'undefined')) {
        $.getScript("https://maps.googleapis.com/maps/api/js?v=3.0&libraries=places&language=en&key=AIzaSyDmeD92wg9vYQeBU8maSJGedwWMuHLbOvE", function() {
          _this.setAddress(_this);
        });
      } else {
        _this.setAddress(_this);
      }

    });
  }

  setAddress(l_this) {
    var input = document.getElementById('gsearch');
    var searchBox = new google.maps.places.SearchBox(input);
    var componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_3: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
        };

    searchBox.addListener('places_changed', function() {
      var places = searchBox.getPlaces();

      var lon, lat, addr, city, country, country_code;
      

      if (places.length == 0) {
        return;
      }

      places.forEach(function(place) {
        var street_number = "";
        var route = "";

        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];

          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            
            if (addressType == "country") {
              country = val;
              country_code = place.address_components[i]["short_name"];
            } else if (addressType == "locality") {
              city = val;
            } else if (addressType == "administrative_area_level_3" && city == null) {
              city = val;
            } else if (addressType == "route") {
              route = val;
            } else if (addressType == "street_number") {
              street_number = val;
            } 
          }
          addr = route + " " + street_number;
        }

        lat = place.geometry.location.lat();
        lon = place.geometry.location.lng();
      });
        
      l_this.getControllerByIdentifier("cards-actions").setAddressInputs(lon, lat, addr, city, country, country_code)
      
    });
  }

}
