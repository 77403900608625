import consumer from "./consumer"
import {MDCSnackbar} from '@material/snackbar';

function snackBarTemplate(data) {
  return `<div class="mdc-snackbar"><div class="mdc-snackbar__surface"><div class="mdc-snackbar__label" role="status" aria-live="polite"> ${data.body} </div><div class="mdc-snackbar__actions"><button type="button" class="mdc-button mdc-snackbar__action">Refresh</button></div></div></div>`;
}

function newCommentTemplate(data) {
  return `<div class='mdc-card comment refresh'>New comment is available <a class="new_comment" data-remote="true" href="/en/comments?card_id=${data.id}">Click to refresh</a></div>`;
}

consumer.subscriptions.create({channel: 'NotificationsChannel'}, { 
  received: function(data) {
    //alert(JSON.stringify(data));
    //https://stackoverflow.com/questions/50679264/mdcsnackbarfoundation-class-usage

    if (data.type == "comment")
      comment(data);
    else if (data.type == "notification")
      notification(data);

  },
  connected: function(data) {
    //sendNotification('system_message', {});
  },
  disconnected:  function(data) {},
  renderMessage: function(data) {}
});

function notification(data) {
  $.get( "/en/notifications/" + data.id + "?with_unread=t")
}

function comment(data) {
  if ($(".cards_controller.show_action").length) {
    //location.reload();
    var classList = $(".cards_controller.show_action").attr('class').split(/\s+/);

    $.each(classList, function(index, item) {
        if (item === ("id_" + data.id)) {
          $(".cards_controller.show_action").append(snackBarTemplate(data));

          //
          $(".comments").append(newCommentTemplate(data));  
          $(".new_comment").on('ajax:success', newCommentNotification);

          //
          var snackbarElement = document.querySelector('.mdc-snackbar');

          const snackbar = new MDCSnackbar(snackbarElement);
          snackbar.open();

          snackbarElement.addEventListener("MDCSnackbar:closed", (event) => {
            // Maybe add a delay to keep animation.
            snackbarElement.remove();
          });

          snackbar.handleActionButtonClick(function(event) {alert();});
        }

    });

  }
}

function sendNotification(type, data) {
  consumer.send({  
    type: type,
    data: data
  });
}

function newCommentNotification(event) {
  var data = event.detail[0];
  $('.comments').html(data.comments);
}