var root_path = window.location.protocol + '//' + window.location.host;

$('.inline_popup').magnificPopup({
  type: 'inline',
  alignTop: true
});

$('.ajax_popup').magnificPopup({
  type: 'ajax',
  focus: '.white-popup input',
  alignTop: true
});

$('.iframe_popup').magnificPopup({
  type: 'iframe',
  alignTop: true
});

$('.ajax_popup_confirm').magnificPopup({
  type: 'ajax',
  midClick: true,
  alignTop: true,
  callbacks: {
    beforeClose: function() {
    },
    open: function() {
      $.magnificPopup.instance.close = function() {
        // Do whatever else you need to do here
        var confirmed = confirm("Are you sure?");
        if(!confirmed) {
          return;
        }

        // Call the original close method to close the popup
        $.magnificPopup.proto.close.call(this);
      };
    }
  }
});


$(document).on("keypress", ".prevent_enter input", function(e) {
  if(event.keyCode == 13) {
    event.preventDefault();
    return false;
  }
});

$(document).on("click", ".invitation_codes .copy_link", function(e) {
  $(this).parent().find('.link').focus();
  $(this).parent().find('.link').select();
  document.execCommand('copy');
});

var mutationObserver = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    window.mdcAutoInit();
    
    $('.ajax_popup').magnificPopup({
      type: 'ajax',
      focus: '.white-popup input',
      alignTop: true
    });
  });
});

mutationObserver.observe(document.documentElement, {
  childList: true,
  subtree: true,
});