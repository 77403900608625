import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tagid", "tagsids", "sid", "lat", "lon", "addr", "country", "city", "code", "address", "period"]

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (typeof(data) === 'object') {
      window.location.href = "/en/cards/" + data.card_id;
    } else {
      var elem = document.getElementById("card_" + event.target.dataset.uid);

      if(elem != null && elem !== 'undefined' ) {
        elem.outerHTML = data;
      }
      
      $.magnificPopup.instance.close("no_warn");
    }
  }

  onPostSuccessCardShow(event) {
    let [data, status, xhr] = event.detail;
    document.getElementById("card_show").outerHTML = data;
    $.magnificPopup.proto.close();
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  setMainTagId(event) {
    $(".main_tag .mdc-chip").removeClass('mdc-chip--selected');
    
    let tagid = event.target.parentElement.dataset.tagid;
    let tag_name = event.target.parentElement.dataset.sid;

    if (!JSON.stringify(event.target.parentElement.classList).includes("mdc-chip-set"))
      event.target.parentElement.classList.add("mdc-chip--selected");
    
    //Questions
    $.get( "/en/questions/form", {
      cat_id: tagid,
      card_id: $("[data-uid]").attr("data-uid")
    })
    .done(function( data ) {
      $("#dynamic_questions").html(data);
    });

    //Tags
    $.get( "/en/tags/form", {
      main_cat_id: tagid,
      card_id: $("[data-uid]").attr("data-uid")
    })
    .done(function( data ) {
      $("#sub_tags_form").html(data);
    });


    this.tagidTarget.value = tagid;
    this.reorganiseFormFor(tag_name);
  }

  setPeriod(event) {
    $(".period .mdc-chip").removeClass('mdc-chip--selected');
    
    if (!JSON.stringify(event.target.parentElement.classList).includes("mdc-chip-set"))
      event.target.parentElement.classList.add("mdc-chip--selected");
    this.periodTarget.value = event.target.parentElement.dataset.period;
  }

  setSubTagId(event) {
    let tags = [];

    if (JSON.stringify(event.target.parentElement.classList).includes("mdc-chip--selected"))
      event.target.parentElement.classList.remove("mdc-chip--selected");
    else
      event.target.parentElement.classList.add("mdc-chip--selected");

    $(".sub_tag .mdc-chip--selected").each(function( index ) {
      tags.push($( this ).data("tagid"));
    });

    this.tagsidsTarget.value = tags;
  }

  reorganiseFormFor(sid) {
    $("form .dynamic.active").removeClass("active");
    $("form .dynamic.tag_" + sid).addClass("active");
    //sub_tag
    $("form .sub_tag .active").removeClass("active");
    $("form .sub_tag ." + sid).addClass("active");
  }

  setAddressInputs(lon, lat, addr, city, country, country_code) {
    this.lonTarget.value = lon;
    this.latTarget.value = lat;
    this.addrTarget.value = addr;
    this.cityTarget.value = city;
    this.countryTarget.value = country;
    this.codeTarget.value = country_code;

    this.addressTarget.value = country;

    $("#gsearch").parent().attr("data-mdc-auto-init-state", null);
    window.mdcAutoInit();

  }

  resetAddress(event) {
    if (this.addressTarget.value.length == 0) {
      this.lonTarget.value = null;
      this.latTarget.value = null;
      this.addrTarget.value = null;
      this.cityTarget.value = null;
      this.countryTarget.value = null;
      this.codeTarget.value = null;
    } else {
      this.addressTarget.value = this.countryTarget.value;
    }
  }

  changeTab(event) {
    let gid = event.currentTarget.dataset.gid;
    $(".white-popup .tab_group.active").removeClass("active");
    $(".white-popup .tab_group.g" + gid).addClass("active");

    //TO DO: Improve
    var elements = $("[data-mdc-auto-init='MDCTextField']");
    elements.each(function( index ) {
      $( this ).attr("data-mdc-auto-init-state", null);
    });
    var components = window.mdcAutoInit();
    components.forEach((c) => c.layout && c.layout());
  }
}