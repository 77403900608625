// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
//import $ from "jquery/dist/jquery.min"

export default class extends Controller {
  static targets = [ "output", "title", "body", "edit" ]

  // onPostSuccess(event) {
  //   let [data, status, xhr] = event.detail;
  //   this.outputTarget.innerHTML = xhr.response;
  //   this.titleTarget.value = "";
  //   this.bodyTarget.value = "";
  // }

  getcontacts(event) {
    let filter = null;

    if (event)
      filter = event.currentTarget.dataset.filter;

    let _this = this;
    var contactAPI = "/en/contacts";
    //this.messageTarget.value = "";

    if (filter != null) {
      $(".filter .active").removeClass("active");
      event.currentTarget.className = "active";
    }

    $.get( contactAPI, {
      filter: filter
    })
    .done(function( data ) {
      //_this.outputTarget.innerHTML = "";
      _this.outputTarget.innerHTML = data;
    });
    
    $.magnificPopup.proto.close();
  }

  showEditForm(event) {
    //alert("Edit");
    let contact_id =  event.target.dataset.uid;
    
    fetch("/en/contacts/"+contact_id+"/edit")
    .then(data => {return data.text();})
    .then(html => {
      $.magnificPopup.open({
        items: {
          src: html, // can be a HTML string, jQuery object, or CSS selector
          type: 'inline'
        },
        alignTop: true
      });

    });
  }
}
